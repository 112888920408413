
.container {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 50%);
    /* background-color: black; */
    margin: 50vh auto;
  }
  
  .sketch {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }