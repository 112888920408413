:root {
    --main-background: #05336B;
    --secondary-background: #07192d;
    --color1:#59CBE8;
    --color2:#0C70EB;
    --color3:#74a5e0;
  }


.resumeDiv{
 display: flex;
 flex-direction: column;   
}

.resumeHeader{
    margin-top: 20px;
    align-self: center;
    border-bottom: 2px solid var(--color1);
}

.resumeName{
font-size: 50px;
color: var(--color1);
}
.resumeSubTitle{
    align-self: center;
    text-align: center;
    font-size: 30px;
color: var(--color3);
}

.resumeBody{
    margin:10px auto;
    display: flex;
    flex-direction: row;
    width: 750px;
        background-color: #59CBE8;
}

.resumeLeft{
    background-color: var(--color2);
    width: 33%;
    padding-left: 10px;
}

.resumeLeftBlock{
    background-color: var(--color2);
    font-size:15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
}   

.resumeLeftBlockHead{ 
    background-color: #2262b1;
    color:aliceblue;
    text-align: center;
    font-size: 25px;
    border-bottom: 2px solid aliceblue;
    padding-left: 50px;
    padding-right: 50px;
    width: fit-content;
}


.resumeLeftItemBlock{
    margin-top: 10px;
    background-color: var(--color2);
   display: flex;
 align-items: center;
 flex-direction: column;
 color:aliceblue;
 font-size: 20px;
 
}

.resumeLeftItemHead{
    background-color: var(--color2);
    font-size: 20px;
color: aliceblue;
}

.resumeLeftItemBody{
    padding: 5px;
   display: flex;
   align-items: center;
   background-color: var(--color2);
    color:aliceblue;
    text-align: center;
}


.resumeLeftButtonLink{
    /* min-height: fit-content; */
    margin-top: 5px;
    background-color: #0C70EB;
    align-self: center;
    width: 150px;
    text-align: center;
    font-size: 20px;
    color:aliceblue;
    text-decoration: none;
    border: 2px solid aliceblue;
    padding: 5px 15px;
    border-radius: 3px;
    transition: 0.3s;
    cursor: pointer;
 
}


.resumeHoverImage{
 display: none;
 
}




.resumeHoverImage:hover{
    display: flex;
   }




.resumeRightBlock{
    background-color: aliceblue;
    width: 67%;
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.resumeRightHead{
    background-color: aliceblue;
    margin: 5px;
}
.resumeRightDivider{
background-color: aliceblue;
font-size: 20px;
border-top: 2px solid var(--color3);
border-bottom: 2px solid var(--color3);
color: black;
}
.resumeRightProjectBlock{
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    align-items: center;
    background-color: aliceblue;
    margin-bottom: 10px;
}
.projectTitle{
    background-color: aliceblue;
    font-size: 25px;
}
.projectSubTitle{
    background-color: aliceblue;
}
.projectDescList{
    background-color: aliceblue;
}
.projectDescription{
    background-color: aliceblue;
    align-items: center;
}

.projectDescItem{
    align-items: center;
    margin-left: 20px;
    background-color: aliceblue;
    font-size: 15px;
    margin-top: 5px;
}
.workHistoryBlock{
    background-color: aliceblue;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
.workDates{
    background-color: aliceblue;
    margin-left: 5px;
}
.workInfoBlock{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    
}
.workTitle{
    background-color: aliceblue;
    font-size: 20px;
    text-align: center;
}
.workLocation{
    background-color: aliceblue;
    text-align: center;
}
.workDetailItem{
    background-color: aliceblue;
}
.schoolInfoBlock{
    text-align: center;
    margin:auto
}