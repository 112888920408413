:root {
    --main-background: #05336B;
    --secondary-background: #07192d;
    --color1:#59CBE8;
    --color2:#0C70EB;
    --color3:#74a5e0;
  }


.contactDiv{ /* background-color:var(--main-background); */
    width: 750px;
    margin: 50px auto;
    height: 450px;
    /* border: 5px solid var(--color3); */
    display: flex;
    flex-direction: column;
align-items: center;
}

 .singleContactPointDiv{
  display: flex;
  flex-direction: column;  
  color:var(--color3);
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
 }
 .contactLinkTitle{
    align-self: center;
    font-size: 30px;
    border-bottom: 2px solid var(--color1);
 }
 .contactLinkText{
    padding: 7px;
 }
.buttonLink{
    align-self: center;
    width: fit-content;
    font-size: 25px;
    color:var(--color1);
    text-decoration: none;
    border: 2px solid var(--color1);
    padding: 10px 15px;
    border-radius: 3px;
    transition: 0.3s;
}

.buttonLink:hover{
    background-color: #59cbe880;
  
}