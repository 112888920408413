:root {
    --main-background: #05336B;
    --secondary-background: #07192d;
    --color1:#59CBE8;
    --color2:#0C70EB;
    --color3:#74a5e0;
  }


.aboutMePageDiv{
    color:var(--color3);
    font-size: 20px;
    display: flex;
    flex-direction: row;
    ;
}

.aboutMeContainer{
    width: 750px;
    margin: 50px auto;
    height: 450px;
    /* border: 5px solid var(--color3); */
    display: flex;
    flex-direction: column;
    
}

.aboutMeTitle{
color:var(--color1);
font-size: 40px;
border-bottom: 5px solid var(--color1);
text-align: center;
width: 200px;
margin-bottom: 10px;
align-self: center;
}

.aboutMeImg{
    width: 250px;
    margin-left: 5px;
}


.aboutMeText2{
    margin-top: 15px;
    color:var(--color3);
    font-size: 20px;
}