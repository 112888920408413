:root {
    --main-background: #05336B;
    --secondary-background: #07192d;
    --color1:#59CBE8;
    --color2:#0C70EB;
    --color3:#74a5e0;
  }




.resumodalDiv{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
align-items: center;
justify-content: center;
background-color: var(--color2);
border: 2px solid aliceblue;
border-radius: 10px;
width: 750px;
height:500px
}

.certContainer{
    width: fit-content;
    height: fit-content;
}



.closeButton{
margin-top: 5px;
width: 20px;
height:20px;
display: flex;
align-items: center;
justify-content: center;
align-self: flex-start;
background-color: #0C70EB;
color: aliceblue;
border:2px solid aliceblue;
border-radius: 50px;
cursor: pointer;
}